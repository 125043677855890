<template>
  <v-container>
    <v-row>
      <v-col
        v-for="field in fieldsToShow"
        :key="field.key"
        :cols="field.cols ? field.cols : 12"
        style="padding-bottom:0"
      >

        <v-text-field
          v-if="(!field.disabled && (field.type == 'text' || field.type == 'password' || field.type == 'number'))"
          :label="field.label"
          v-model="field.value"
          :type="field.type"
          :required="field.required ? field.required : false"
          :rules="field.rules && !field.readonly ? field.rules : []"
          :prepend-inner-icon="field.prependInnerIcon ? field.prependInnerIcon : null"
          :autofocus="field.autofocus ? true : false"
          :readonly="field.readonly ? true : false"
          :disabled="field.disabled ? true : false"
          v-mask="field.mask ? field.mask : ''"
          :placeholder="field.placeholder ? field.placeholder : ''"
          outlined
          dense
        ></v-text-field>

        <v-textarea
          v-if="(field.type == 'textarea')"
          :label="field.label"
          v-model="field.value"
          :required="field.required ? field.required : false"
          :rules="field.rules && !field.readonly ? field.rules : []"
          :prepend-inner-icon="field.prependInnerIcon ? field.prependInnerIcon : null"
          :autofocus="field.autofocus ? true : false"
          :readonly="field.readonly ? true : false"
          :disabled="field.disabled ? true : false"
          :rows="field.rows ? field.rows : 3"
          outlined
          dense
        ></v-textarea>

        <v-select
          v-if="field.type == 'select'"
          v-model="field.value"
          :items="field.items"
          :item-text="field.itemText ? field.itemText : null"
          :item-value="field.itemValue ? field.itemValue : null"
          :chips="field.chips ? true : false"
          :small-chips="field.chips ? true : false"
          :label="field.label"
          :multiple="field.multiple ? true : false"
          :readonly="field.readonly ? true : false"
          :rules="field.rules && !field.readonly ? field.rules : []"
          @change="dispatchEvent(field.changeEvent, $event)"
          dense
          outlined
        ></v-select>

        <v-radio-group
          v-model="field.value"
          v-if="field.type == 'radio'"
          :mandatory="field.required"
          row
        >
          <v-radio
            :label="item.label"
            :value="item.value"
            v-for="item in field.items"
            :key="item.value"
          ></v-radio>
        </v-radio-group>

        <v-checkbox
          v-if="field.type == 'checkbox'"
          :label="field.label"
          v-model="field.value"
        ></v-checkbox>

        <v-switch
          v-if="field.type == 'switch'"
          v-model="field.value"
          :label="field.label"
        ></v-switch>

        <v-datetime-picker
          v-if="field.type == 'datetime'"
          date-format="dd/MM/yyyy"
          time-format="HH:mm"
          :label="field.label"
          v-model="field.value"
          :text-field-props="computeDateTimeFieldProps(field)"
          :time-picker-props="{'format': '24hr'}"
          :date-picker-props="{'locale': 'pt'}"
        >
          <template v-slot:dateIcon>
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template v-slot:timeIcon>
            <v-icon>mdi-clock-outline</v-icon>
          </template>
        </v-datetime-picker>

        <div v-if="field.type == 'date'">
          <DatePicker
            :field-name="field.name"
            :value="field.value"
            :label="field.label"
            @set-value="setDateValue"
            :rules="field.rules ? field.rules : []"
          ></DatePicker>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'
import DatePicker from '@/components/DatePicker'

export default {
  name: 'DynamicFormContent',

  components: {
    DatePicker
  },

  props: {
    fields: {
      type: Array
    }
  },

  data: () => ({
    menuDatePicker: false
  }),

  methods: {
    dispatchEvent(eventName, value) {
      if (eventName) {
        this.$emit(eventName, value)
      }
    },

    computeDateTimeFieldProps(field) {
      let props = {'outlined': true}

      if (field.rules) {
        props.rules = field.rules
      }

      return props
    },

    setDateValue(data) {
      Vue.set(this.fields.find(field => field.name == data.fieldName), 'value', data.value)
    }
  },

  computed: {
    fieldsToShow() {
      return this.fields.filter(f => !f.hide);
    },
  }
}
</script>
