<template>
  <v-menu
    v-model="menuDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        style="padding-top:0"
        :value="formatedValue"
        :label="label"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        outlined
        dense
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="originalValue"
      @input="menuDatePicker = false"
      :locale="locale"
    ></v-date-picker>
  </v-menu>

</template>

<script>

import i18n from '@/plugins/i18n'
import { DateTime } from "luxon"

export default {
  name: 'DatePicker',

  props: {
    fieldName: String,
    label: String,
    value: String,
    rules: Array
  },

  data: () => ({
    menuDatePicker: false,
    originalValue: null
  }),

  watch: {
    value: {
      handler() {
        this.originalValue = this.value
      }
    },

    originalValue: {
      handler() {
        this.$emit('set-value', {fieldName: this.fieldName, value: this.originalValue})
      }
    }
  },

  computed: {
    locale() {
      return i18n.locale
    },

    formatedValue() {
      return this.originalValue ? DateTime.fromISO(this.originalValue).setLocale(i18n.locale).toLocaleString() : ''
    },
  }
}
</script>
