<template>
  <v-dialog
    v-model="modal"
    persistent
    scrollable
    max-width="600px"
    @close="resetForm"
  >
    <v-form 
      ref="form"
      @submit.prevent="submitForm"
    >
      <v-card>
        <v-card-title>
          <v-icon
            v-if="titleIcon"
            style="margin-right:10px"
            large
          >
            {{ titleIcon }}
          </v-icon>
          <span class="headline">{{ title }}</span>
        </v-card-title>

        <v-card-text>

          <slot name="content"></slot>

        </v-card-text>

        <v-card-actions>
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          ></v-progress-circular>

          <v-spacer></v-spacer>
          
          <v-btn
            color="cancelButton"
            text
            @click="modal = false"
          >
            {{ $t('Close') }}
          </v-btn>

          <v-btn
            v-if="!deleteModal && !hideSaveButton"
            :loading="submitting"
            color="primary"
            text
            type="submit"
          >
            {{ $t(submitButtonText || 'Save') }}
          </v-btn>

          <v-btn
            v-if="deleteModal"
            :loading="submitting"
            color="deleteButton"
            text
            type="submit"
          >
          {{ $t(submitButtonText || 'remove') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>
<script>

export default {
  name: 'DefaultModal',

  data: () => ({
    modal: false,
    loading: false,
    submitting: false
  }),

  watch: {
    modal(from) {
      if (from == false) {
        this.resetForm()
        this.$emit('modal-closed')
      }
    }
  },

  props: {
    title: {
      type: String,
    },

    titleIcon: String,

    deleteModal: {
      type: Boolean,
      default: false
    },

    submitButtonText: {
      type: String,
    },

    hideSaveButton: {
      type: Boolean,
      default: false
    },

    preventResetForm: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggleModal() {
      this.modal = !this.modal
    },

    openModal() {
      this.modal = true
    },

    closeModal() {
      this.modal = false
    },

    submitForm() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.submitting = true

      this.$emit('submit')
    },

    resetForm() {
      this.$nextTick(() => {
        this.submitting = false

        if (!this.preventResetForm && this.$refs.form) {
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
        }        
      })
    }
  }
}
</script>
