<template>
  <v-container :fluid="fluid">
    <v-toolbar class="elevation-0">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>

      <v-btn
        style="margin-left:20px"
        class="primary"
        @click="openNewModal"
        v-if="showNewButton"
        small
      >
        {{ newButtonTitle }}
      </v-btn>

      <v-spacer></v-spacer>

      <span
        v-for="(filter, i) in filters"
        :key="i"
      >
        <v-select
          style="margin-top:25px; margin-left: 10px; max-width:180px"
          v-if="filter.type == 'select'"
          :items="filter.items"
          v-model="filter.value"
          :label="filter.label"
          :item-value="filter.itemValue"
          :item-text="filter.itemText"
          @change="$emit(filter.event, filter)"
          :clearable="filter.clearable === false ? false : true"
          outlined
          dense
        ></v-select>
      </span>

      <v-switch
        style="margin-top:22px"
        v-model="onlyActive"
        :label="$t('Only active')"
        @click="reloadData"
        v-if="activeSwitch"
      ></v-switch>

      <slot name="beforesearch"></slot>

      <v-text-field
        style="margin-left: 10px; max-width:180px"
        v-if="!hideSearchField"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('Search')"
        @keydown.enter="reloadSearch"
        single-line
        hide-details
        outlined
        dense
      ></v-text-field>

      <slot name="aftersearch"></slot>

    </v-toolbar>

    <v-divider style="margin-bottom:18px"></v-divider>

    <slot name="table">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0"
        :loading="loading"
        :options.sync="options"
        :items-per-page="20"
        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100]}"
        :server-items-length="itemsLength"
      >
        <template v-for="ct in customTableTemplates" v-slot:[ct.name]="{ item }">
          {{ ct.callback(item) }}
        </template>

        <template v-for="(bc, i) in boolColumns" v-slot:[bc]="{ item }">
          <v-icon :key="i" color="green" v-if="item[bc.replace('item.', '')]">mdi-check</v-icon>
        </template>

        <template v-for="(pc, i) in priceColumns" v-slot:[pc]="{ item }">
          <span :key="i" v-if="item[pc.replace('item.', '')]">{{  convertPriceValue(item[pc.replace('item.', '')]) | VMask(priceMask) }}</span>
        </template>

        <template v-for="(dc, i) in dateColumns" v-slot:[dc]="{ item }">
          <span :key="i" v-if="item[dc.replace('item.', '')]">{{  parseDateValue(item[dc.replace('item.', '')]) }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-for="action in actions"
            :key="action.key"
            class="mr-2"
            @click="$emit(action.event, item)"
          >
            {{ action.icon }}
          </v-icon>

          <v-icon
            class="mr-2"
            @click="edit(item)"
            v-if="!hideEditAction"
          >
            mdi-pencil
          </v-icon>

          <v-icon
            class="mr-2"
            @click="openDeleteModal(item)"
            v-if="!hideDeleteAction"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </slot>

    <DefaultModal
      :title="modalTitle"
      ref="modal"
      v-on:submit="save"
      :submitting="submitting"
      :submitButtonText="selected ? editButtonText : newButtonText"
    >
      <template v-slot:content>
        <DynamicFormContent :fields="fields" />
      </template>
    </DefaultModal>

    <DefaultModal 
      :title="$t('Delete ' + path)"
      ref="deleteModal"
      @submit="deleteItem"
      :submit-button-text="'Delete ' + path"
      deleteModal
    >
      <template v-slot:content>
        {{ $t('Do you really want to delete the ' + path + '?') }}
      </template>
    </DefaultModal>

  </v-container>

</template>

<script>

import Vue from 'vue'
import api from '@/services/api'
import i18n from '@/plugins/i18n.js'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { DateTime } from "luxon"

export default {
  name: 'BasicCrud',

  components: {DefaultModal, DynamicFormContent},

  props: {
    fields: [],
    showNewButton: {
      type: Boolean,
      default: true,
    },
    title: String,
    newTitle: {
      type: String,
      default: i18n.t('Create new')
    },
    newButtonTitle: {
      type: String,
      default: i18n.t('New')
    },
    newButtonText: {
      type: String,
      default: i18n.t('Save')
    },
    editButtonText: {
      type: String,
      default: i18n.t('Save')
    },
    editTitle: {
      type: String,
      default: i18n.t('Edit')
    },
    path: String,
    customHeaders: Array,
    customSaveEvent: String,
    customEditEvent: String,
    customEditClickEvent: String,
    customTableTemplates: Array,
    boolColumns: Array,
    priceColumns: Array,
    dateColumns: Array,
    actions: [],
    readOnlyNewFields: Array,
    readOnlyEditFields: Array,
    disabledNewFields: Array,
    disabledEditFields: Array,
    newFocusField: String,
    editFocusField: String,
    defaultNewValues: Array,
    customSearchEvent: String,
    hideEditAction: Boolean,
    hideDeleteAction: Boolean,
    fluid: {
      type: Boolean,
      default: false
    },
    filters: Array,
    hideSearchField: {
      type: Boolean,
      default: false
    },
    activeSwitch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      options: {},
      itemsLength: 0,
      items: [],
      search: "",
      lastSearch: "",
      submitting: false,
      selected: {},
      priceMask: createNumberMask({
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        thousandsSeparatorSymbol: i18n.t('thousandSeparatorSymbol'),
        decimalSymbol: i18n.t('decimalSymbol'),
        prefix: i18n.t('currencyPrefix')
      }),
      onlyActive: true
    }
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  computed: {
    headers() {
      if (this.customHeaders && this.customHeaders.length) {
        return this.customHeaders
      }

      let headers = []

      this.fields.forEach(field => !field.hideOnTable && headers.push({text: field.label, value: field.name}))

      let fieldId = headers.find(field => field.value == 'id')

      if (fieldId) {
        fieldId.width = 90
      }

      headers.push({text: i18n.t('Actions'), value: 'actions', sortable: false, width: 160})

      return headers
    },

    modalTitle() {
      return this.selected ? this.editTitle : this.newTitle
    }
  },

  methods: {
    reloadData() {
      this.loading = true

      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.page = this.options.page
      options.search = this.search

      if (this.activeSwitch && this.onlyActive) {
        options["filters[active]"] = 1
      }

      api
        .find(this.path, options)
        .then((response) => {
          this.items = response.data.results
          this.itemsLength = response.data.total
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    save() {
      let data = {}

      this.fields.forEach((item) => {
        if (item.name != 'id') {
          if (this.selected && (!this.disabledEditFields || this.disabledEditFields.indexOf(item.name) == -1)) {
            data[item.name] = item.value
          }

          if (!this.selected && (!this.disabledNewFields || this.disabledNewFields.indexOf(item.name) == -1)) {
            data[item.name] = item.value
          }

          if (this.priceColumns && this.priceColumns.indexOf('item.' + item.name) > -1) {
            data[item.name] = parseFloat(item.value
              .replace(i18n.t('currencyPrefix'), "")
              .replace(i18n.t('thousandSeparatorSymbol'), "")
              .replace(i18n.t('decimalSymbol'), ".")
            )
          }

          if (typeof item.value == 'object' && item.value && item.value.id) {
            data[item.name] = item.value.id
          }

          if (Array.isArray(item.value) && item.value.length > 0 && item.value[0].id) {
            data[item.name] = item.value.map((it) => it.id)
          }
        }
      })

      if (this.selected) {
        if (this.customEditEvent) {
          this.$emit(this.customEditEvent, data)
          return
        }

        api.update(this.path, data, this.selected.id)
          .then(() => {
            this.reloadData()
            this.$refs.modal.closeModal()
          })
          .catch(() => {
            this.loading = false
            this.$refs.modal.submitting = false
          })
      } else {
        if (this.customSaveEvent) {
          this.$emit(this.customSaveEvent, data)
          return
        }

        api.create(this.path, data)
          .then(() => {
            this.reloadData()
            this.$refs.modal.closeModal()
          })
          .catch(() => {
            this.loading = false
            this.$refs.modal.submitting = false
          })
      }
    },

    openNewModal() {
      Vue.set(this, 'selected', null)

      this.fields.forEach((field) => {
        Vue.set(field, 'readonly', false)
        Vue.set(field, 'disabled', false)

        Vue.set(field, 'value', null)

        if (field.mask) {
          Vue.set(field, 'disabled', true)
          this.$nextTick(() => {
            Vue.set(field, 'disabled', false)
          })
        }

        if (this.newFocusField && this.newFocusField == field.name) {
          Vue.set(field, 'autofocus', true)
        }

        if (this.readOnlyNewFields && this.readOnlyNewFields.indexOf(field.name) > -1) {
          Vue.set(field, 'readonly', true)
        }

        if (this.disabledNewFields && this.disabledNewFields.indexOf(field.name) > -1) {
          Vue.set(field, 'disabled', true)
        }

        if (this.defaultNewValues) {
          let defField = this.defaultNewValues.find(defaultField => defaultField.field == field.name)

          if (defField) {
            Vue.set(field, 'value', defField.value)
          }
        }
      })

      this.$nextTick(() => this.$refs.modal.resetForm())

      this.$refs.modal.openModal()
    },

    closeModal() {
      this.$refs.modal.submitting = false
      this.$refs.modal.closeModal()
    },

    openModal() {
      this.$refs.modal.openModal()
    },

    edit(item) {
      this.selected = item

      if (this.customEditClickEvent) {
        this.$emit(this.customEditClickEvent)
        return
      }

      this.fields.forEach((field) => {
        Vue.set(field, 'value', item[field.name])

        Vue.set(field, 'disabled', true)

        if (this.priceColumns && this.priceColumns.indexOf('item.' + field.name) > -1) {
          Vue.set(field, 'mask', null)
          Vue.set(field, 'value', this.convertPriceValue(item[field.name]))
        }

        if (this.dateColumns && this.dateColumns.indexOf('item.' + field.name) > -1) {
          Vue.set(field, 'value', '')
        }

        this.$nextTick(() => {
          if (this.priceColumns && this.priceColumns.indexOf('item.' + field.name) > -1) {
            Vue.set(field, 'mask', this.priceMask)
          }

          if (this.dateColumns && this.dateColumns.indexOf('item.' + field.name) > -1) {
            Vue.set(field, 'value', item[field.name])
          }

          Vue.set(field, 'disabled', false)
        })

        if (this.editFocusField && this.editFocusField == field.name) {
          Vue.set(field, 'autofocus', true)
        }

        Vue.set(field, 'readonly', false)
        Vue.set(field, 'disabled', false)

        if (this.readOnlyEditFields && this.readOnlyEditFields.indexOf(field.name) > -1) {
          Vue.set(field, 'readonly', true)
        }

        if (this.disabledEditFields && this.disabledEditFields.indexOf(field.name) > -1) {
          Vue.set(field, 'disabled', true)
        }
      })

      this.$refs.modal.openModal()
    },

    setSubmitting(submitting) {
      this.$refs.modal.submitting = submitting
    },

    openDeleteModal(item) {
      this.selected = item
      this.$refs.deleteModal.openModal()
    },

    deleteItem() {
      api.delete(this.path, this.selected.id)
        .then(() => {
          this.$emit('set-seleted', null)
          this.$refs.deleteModal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.deleteModal.submitting = false)
    },

    convertPriceValue(price) {
      if (!price) {
        return price;
      }

      return price
        .toFixed(2)
        .toString()
        .replace('.', i18n.t("decimalSymbol"))
    },

    parseDateValue(dateValue) {
      return DateTime.fromISO(dateValue).setLocale(i18n.locale).toLocaleString()
    },

    reloadSearch() {
      if (this.customSearchEvent) {
        this.$emit(this.customSearchEvent, this.search)
        return
      }

      this.reloadData()
    }
  }
}
</script>
